@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins' !important;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body::-webkit-scrollbar {
  width: 7px;
  background-color: #ffffff;
}

body::-webkit-scrollbar:horizontal {
  height: 7px;
}

body::-webkit-scrollbar-track {
  border: 1px #ffffff solid;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 6px #c8c8c8 inset;
  box-shadow: 0 0 6px #c8c8c8 inset;
}

body::-webkit-scrollbar-thumb {
  background-color: #212121;
  border: 1px solid #212121;
  border-radius: 16px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #212121;
  border: 1px solid #212121;
}

body::-webkit-scrollbar-thumb:active {
  background-color: #212121;
  border: 1px solid #212121;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

body>div,
p,
span,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins' !important;
}

div,
p,
span,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins' !important;
}

.main-container {
  /* max-width: 50vw; */

}